import React, { useState, useEffect } from 'react'
import Pagination from '../pagination/Pagination';
import axios from 'axios'
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';

// Import Material Icons



const Advertisement = () => {

  const [ad_image, set_ad_image] = useState()
  const [ad_page, set_ad_page] = useState()

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [ad, setAd] = useState([])
  const [showPerPage, setShowPerPage] = useState(10)
  const [pagination, setPagination] = useState(
    {
      start: 0,
      end: showPerPage
    }
  )

  const onChangePagination = (start, end) => {
    setPagination({ start: start, end: end })
  }

  const advertisement_get = async () => {
    const ad_page = 'web'
    const res = await axios.post(`https://lions320dapi.emedha.in/advertise/get`, {
      ad_page
    })
    setAd(res.data)
  }

  const advertisement = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append('file', ad_image)
    formData.append('ad_page', ad_page)

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    axios.post("https://lions320dapi.emedha.in/advertise/post", formData, config)
      .then((response) => {
        alert(response.data);
        setTimeout(() => setShow(false), 200)
        advertisement_get()
      })
  }


  useEffect(() => {
    advertisement_get()
  }, []);


  const deleteAd = (id) => {
    if (window.confirm('Are you sure to delete this data...')) {
      axios.post(`https://lions320dapi.emedha.in/advertise/delete`, {
        id
      })
      alert('Data deleted successfully!...')
      advertisement_get()
    }
  }

  return (

    <>
      <Button variant="warning" onClick={handleShow}>
        Add<PersonAddAltIcon></PersonAddAltIcon>
      </Button>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={advertisement}>
            <label style={{ marginLeft: '0.1rem' }}>Advertise page </label>
            <select type='text' className='form-control' placeholder='Route' onChange={(e) => set_ad_page(e.target.value)} >
              <option value='' disabled={false} >
                Advertise page
              </option>
              <option value='login'>
                login
              </option>
              <option value='signup'>
                signup
              </option>
              <option value='club'>
                club
              </option>
              <option value='member'>
                member
              </option>
              <option value='index'>
                index
              </option>
            </select>
            <br />
            <label style={{ marginLeft: '0.1rem' }}>Advertise Image </label>
            <input type='file' className='form-control' placeholder='' name='file' onChange={(e) => set_ad_image(e.target.files[0])} />
            <br />
            <button type='submit' className='btn btn-danger form-control'>Submit</button>
          </form>
        </Modal.Body>

      </Modal>
      <div style={{ marginTop: '1rem' }} className='table-container'>
        <table className="styled-table table table-bordered">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Image</th>
              <th>Ad Page</th>
            </tr>

          </thead>
          <tbody className='table-bordered'>
            {

              ad.slice(pagination.start, pagination.end).map((data, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <img src={`${data.image}`} width='130px' />
                    <td>{data.ad_page}</td>
                    <td className=''>
                      <Link to='#' className='btn btn-danger' onClick={() => deleteAd(data.id)}>delete</Link>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <Pagination page={showPerPage} onChangePagination={onChangePagination} total={ad.length} />
      </div>
    </>
  )
}

export default Advertisement