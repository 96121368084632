import * as React from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import ViewClub from '../AdminDataBaseSystem/ViewClub';
import Home from '../AdminDataBaseSystem/Home';
import Projects from '../AdminDataBaseSystem/Projects';
import Events from '../AdminDataBaseSystem/Events';
import Member from '../AdminDataBaseSystem/Member';
import Report from '../AdminDataBaseSystem/Report';
import Calender from '../AdminDataBaseSystem/Calender';
import Cabinet from '../AdminDataBaseSystem/Cabinet';
import InputProjects from '../AdminDataBaseSystem/InputProjects';
import InputEvent from '../AdminDataBaseSystem/InputEvent';
import Update from '../AdminDataBaseSystem/Update';
import DGdairyInput from '../AdminDataBaseSystem/DGDiaryInput';
import UpdateClub from '../AdminDataBaseSystem/UpdateClub';
import { Avatar } from '@mui/material';
import { FaCaretDown } from 'react-icons/fa';
import LogoutIcon from '@mui/icons-material/Logout';
import logo from '../img/320d.jpg';
import Multiple from '../AdminDataBaseSystem/Multiple';
import International from '../AdminDataBaseSystem/International';
import Dollar from '../AdminDataBaseSystem/Dolor';
import InternationalUpdate from '../AdminDataBaseSystem/InternationalUpdate';
import Menu from '../AdminDataBaseSystem/Menu';
import DgDiary from '../AdminDataBaseSystem/DG-Diary';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import ProjectUpdate from '../AdminDataBaseSystem/ProjectUpdate';
import DGDiaryUpdate from '../AdminDataBaseSystem/DGDiaryUpdate';
import EventUpdate from '../AdminDataBaseSystem/EventUpdate';
import Advertisement from '../AdminDataBaseSystem/Advertisement';
import NotificationMessage from '../AdminDataBaseSystem/NotificationMessage';

function Navigation(props) {

  const logout = () => {
    props.condition(sessionStorage.removeItem('email'))
  }

  return (
    <div className='pb-4'>
      <>
        <Navbar className='navbarStyle shadow p-4' fixed='top' bg="white" variant="">
          <Container>
            <Navbar.Brand href="#home"><h2> <strong className='adminDashboardHeading'>Admin Dashboard !...</strong></h2>

              <Link style={{ textDecoration: 'none', fontSize: '1.4rem', color: "rgb(245, 170, 30)" }} to=''>Home/Dashboard  320D</Link>
            </Navbar.Brand>
            <Nav className="ms-auto">
              <div class="dropdown ms-auto">
                <a class="d-flex text-white mt-2" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  <Avatar className='ms-auto'> <img src={logo} height="50px" width="50px" /></Avatar>
                  <span><FaCaretDown className='me-1 mt-3 text-danger'></FaCaretDown></span>
                </a>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li><Link to='#' class="dropdown-item" href="#"> <strong className='text-center text-primary'>Admin</strong></Link></li>
                  <li><Link to='#' class="dropdown-item" href="#"><LogoutIcon></LogoutIcon>  <Link to="#" style={{ color: 'black' }} onClick={logout}>Logout</Link></Link></li>
                </ul>
              </div>
            </Nav>
          </Container>
        </Navbar>

        <Container className='shadow mt-5 link-text'>

          <Routes>
            <Route path='/' element={<Home></Home>} />
            <Route path='/view-project' element={<Projects></Projects>} />
            <Route path='/view-events' element={<Events></Events>} />
            <Route path='/view-calender' element={<Calender></Calender>} />
            <Route path='/view-member' element={<Member></Member>} />
            <Route path='/view-report' element={<Report></Report>} />
            <Route path='/view-club' element={<ViewClub></ViewClub>} />
            <Route path='/view-cabinet' element={<Cabinet></Cabinet>} />
            <Route path='/add-project' element={<InputProjects></InputProjects>} />
            <Route path='/add-event' element={<InputEvent></InputEvent>} />
            <Route path='/add-DG-dairy' element={<DGdairyInput></DGdairyInput>} />
            <Route path='/update/:memberId' element={<Update></Update>} />
            <Route path='/update-club/:clubName/:clubId' element={<UpdateClub></UpdateClub>} />
            <Route path='/multiple' element={<Multiple></Multiple>} />
            <Route path='/internationalUpdate/:id' element={<InternationalUpdate></InternationalUpdate>} />
            <Route path='/international' element={<International></International>} />
            <Route path='/dollar' element={<Dollar></Dollar>} />
            <Route path='/menu' element={<Menu></Menu>} />
            <Route path='/project-update/:id' element={<ProjectUpdate></ProjectUpdate>} />
            <Route path='/DG-diary' element={<DgDiary></DgDiary>} />
            <Route path='/DG-diary-update/:id' element={<DGDiaryUpdate></DGDiaryUpdate>} />
            <Route path='/event-update/:id' element={<EventUpdate></EventUpdate>} />
            <Route path='/advertisement' element={<Advertisement />} />
            <Route path='/notification-message' element={<NotificationMessage />} />
          </Routes>

        </Container>
      </>
    </div>
  );
}

export default Navigation